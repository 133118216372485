import ky, { HTTPError, ResponsePromise } from 'ky';
import { DEBUG, KV_KEY, MIME_TYPE, PATH, TIMES } from './constants';
import { Nonce32, signNonce } from './crypto';
import {
  ClientLoginRequest,
  ServerLoginResponse,
  SignedNonce32,
  Step1LoginState,
} from './types';

async function login(): Promise<void> {
  console.log('Login button click');
  const username = (document.getElementById('uname') as HTMLInputElement).value;
  const password = (document.getElementById('pwd') as HTMLInputElement).value;
  let state = getLoginStateFromKv();
  if (state === null) {
    state = await processLogin(username, password);
    if (state === null) {
      console.error('Login failed');
    } else {
      persistStep1State(state);
      console.log('piggy1 login endpoint');
      //window.location.href = PATH.SUCCESS;
    }
  } else {
    console.log('Step 1 login details found in session storage');
    console.log('piggy2 login endpoint');
  }
}
document.getElementById('submitBtn')?.addEventListener('click', (event) => {
  event.preventDefault();
  login();
});
function getLoginStateFromKv(): Step1LoginState | null {
  const step1KvState = sessionStorage.getItem(KV_KEY.LOGIN.Step1);
  if (step1KvState === null) {
    return null;
  }
  try {
    const state = JSON.parse(step1KvState) as Step1LoginState;
    const cookieKv = sessionStorage.getItem('cookiePresent');
    if (
      state.clientNonce !== '' &&
      state.serverNonce !== '' &&
      state.clientSignature !== '' &&
      state.serverSignature !== '' &&
      state.keyExpiry > new Date(Date.now() + TIMES.fiveMins) &&
      cookieKv !== null &&
      cookieKv !== 'true'
    ) {
      return state;
    } else {
      sessionStorage.removeItem(KV_KEY.LOGIN.Step1);
      sessionStorage.removeItem('cookiePresent');
      sessionStorage.setItem(KV_KEY.LOGIN.Step1, JSON.stringify(state));
      return null;
    }
  } catch (e) {
    console.error('KV store contains value which cannot be parsed: ', e);
    sessionStorage.removeItem(KV_KEY.LOGIN.Step1);
    sessionStorage.removeItem('cookiePresent');
    return null;
  }
}
async function processLogin(
  username: string,
  password: string
): Promise<Step1LoginState | null> {
  if (DEBUG) {
    console.log('Signed nonce -> server');
  }
  const reqBody = createLoginRequestBody(username, password);
  if (DEBUG) {
    console.log('Login request body: ', reqBody);
  }
  const loginUri = PATH.AUTH.Root + PATH.AUTH.LoginStep1;
  const serverLoginRes = await loginPost(loginUri, reqBody);
  if (serverLoginRes === null) {
    console.error('Server login response null.');
    return null;
  }
  const clientNonce = {
    nonce: reqBody.nonce,
    signature: reqBody.signature,
  };
  const state = await validateLoginResponse(clientNonce, serverLoginRes, password);
  if (DEBUG) {
    const msg = 'Bidrectional step 1 authentication ';
    if (state === null) {
      console.error(msg + 'failed');
    }
    console.log(msg + 'successful');
  }
  return state;
}
async function loginPost(
  loginUri: string,
  reqBody: ClientLoginRequest
): Promise<ServerLoginResponse | null> {
  console.log('Sending login request to: ', loginUri);
  // We have to use error handling here because ky will only produce 2xx responses
  const loginResPromise = ky.post(loginUri, {
    hooks: {
      afterResponse: [
        (request, options, response) => {
          if (response.headers.get('status') === '401') {
            injectCardMessage('Invalid credentials');
            throw new HTTPError(response, request, options);
          }
        },
      ],
    },
    headers: {
      'Content-Type': MIME_TYPE.JSON,
    },
    json: reqBody,
  });
  return processLoginResponse(loginResPromise);
}
function createLoginRequestBody(username: string, password: string): ClientLoginRequest {
  const nonce: Nonce32 = Nonce32.generate();
  const clientNonce: SignedNonce32 = signNonce(nonce, password);
  return {
    username,
    nonce: clientNonce.nonce,
    signature: clientNonce.signature,
  };
}
async function processLoginResponse(
  promiseRes: ResponsePromise
): Promise<ServerLoginResponse | null> {
  try {
    const respText = await (await promiseRes).text();
    return JSON.parse(respText) as ServerLoginResponse;
  } catch (e) {
    console.error('Error handled: ', e);
    injectCardMessage(e as string);
  }
  return null;
}
function injectCardMessage(message: string) {
  const cardBody = document.querySelector('.card-body');
  let p = cardBody?.querySelector('.alert.alert-warning.mt-3');
  if (!p) {
    p = document.createElement('p');
    p.className = 'alert alert-warning mt-3';
    cardBody?.appendChild(p);
  }
  p.textContent = message;
}
async function validateLoginResponse(
  clientNonce: SignedNonce32,
  serverLoginRes: ServerLoginResponse,
  password: string
): Promise<Step1LoginState | null> {
  if (DEBUG) {
    console.log('validateLoginResponse()');
  }
  const clt = clientNonce;
  const correctServerSig = signNonce(serverLoginRes.serverNonce, password).signature;
  const expired = new Date(serverLoginRes.keyExpiry).getTime() > Date.now();
  if (serverLoginRes.signature === correctServerSig && expired) {
    const state = constructStep1State(serverLoginRes, clt);
    if (DEBUG) {
      console.log('correctServerSig: ', correctServerSig);
      console.log('serverLoginRes: ', serverLoginRes);
      console.log('actualExpiry: ', expired);
      console.log('3U7 Login state persisted to session storage');
    }
    return state;
  } else {
    console.error('lpD Server login response does not match client request');
    if (DEBUG) {
      console.log(correctServerSig);
      console.log(serverLoginRes);
    }
    return null;
  }
}
function constructStep1State(
  serv: ServerLoginResponse,
  clt: SignedNonce32
): Step1LoginState {
  return {
    username: serv.username,
    clientNonce: serv.clientNonce.b64Bytes,
    serverNonce: serv.serverNonce.b64Bytes,
    clientSignature: clt.signature,
    serverSignature: serv.signature,
    keyExpiry: serv.keyExpiry,
  };
}
function persistStep1State(state: Step1LoginState): void {
  const step1Key = KV_KEY.LOGIN.Step1;
  const cookieKey = KV_KEY.LOGIN.CookiePresent;
  sessionStorage.removeItem(step1Key);
  sessionStorage.setItem(step1Key, JSON.stringify(state));
  sessionStorage.removeItem(cookieKey);
  sessionStorage.setItem(cookieKey, 'true');
}
