export const DEBUG = false;
export const PATH = {
  UPSTREAM_ORIGIN: 'https://id.jmnw.io',
  ROOT: '/',
  KV: {
    Root: '/kv',
    Delete: '/delete',
    List: '/list',
    Get: '/get',
    Put: '/put',
  },
  AUTH: {
    Root: '/auth',
    LoginStep1: '/login-step1',
    Logout: '/logout',
    RegisterNonce: '/register-nonce',
  },
  SUCCESS: '/success.html',
  TEST: '/test',
};
export const KV_KEY = {
  LOGIN: {
    Step1: '_login-1',
    CookiePresent: 'cookie-present',
    SignedClientNonce: 'signed-client-nonce',
  }
}
export const QueryParam = {
  Crypto: {
    ClientNonce: 'cnonce',
  },
};
export const StorageConst = {
  Keys: {
    NoncePairLatest: 'NoncePairLatestKey',
  },
};
export const TIMES = {
  fiveMins: 5 * 60 * 1000,
  tenMins: 10 * 60 * 1000,
  fifteenMins: 15 * 60 * 1000,
  twentyMins: 20 * 60 * 1000,
  thirtyMins: 30 * 60 * 1000,
  oneHour: 60 * 60 * 1000,
};

export enum MIME_TYPE {
  HTML = 'text/html;charset=UTF-8',
  JS = 'application/javascript',
  CSS = 'text/css',
  JSON = 'application/json',
  SVG = 'image/svg+xml',
  PLAIN = 'text/plain',
  BINARY = 'application/octet-stream',
  FORM = 'application/x-www-form-urlencoded',
  MULTIPART = 'multipart/form-data',
  FORM_DATA = 'multipart/form-data',
  DEFAULT = MIME_TYPE.HTML
}