export function deepEqual<T>(obj1: T, obj2: T): boolean {
    if (obj1 === obj2) {
      return true;
    }
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false;
    }
    
    const keys1 = Object.keys(obj1) as (keyof T)[];
    const keys2 = Object.keys(obj2) as (keyof T)[];
    
    if (keys1.length !== keys2.length) {
      return false;
    }
    
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    
    return true;
  }

  export function base64Encode(bytes: Uint8Array): string {
    const binaryString = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    return window.btoa(binaryString);
  }
  export function base64Decode(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
  export function replacer(key: string, value: any) {
    if (value instanceof Uint8Array) {
      return {type: 'Uint8Array', b64Bytes: Buffer.from(value).toString('base64')};
    }
    return value;
  }