import ky from 'ky';

import { PATH, QueryParam, StorageConst } from './constants';
import { NoncePair, SignedNonce32 } from './types';
import { base64Decode, base64Encode } from './util';
import { kmac256 } from '@noble/hashes/sha3-addons';

export class Nonce32 {
  readonly b64Bytes: string;

  constructor(bytes: Uint8Array) {
    if (bytes.length !== 32) {
      throw new Error('Array must have exactly 32 bytes.');
    }
    this.b64Bytes = base64Encode(bytes);
  }
  /**
   * Creates a Nonce32 instance from a base64 string.
   * @param base64 - The base64 string representing the nonce.
   * @returns A Nonce32 instance.
   */
  static fromBase64(base64: string): Nonce32 {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    if(bytes.length !== 32) {
      throw new Error('Array must have exactly 32 bytes.');
    }
    return new Nonce32(bytes);
  }
  /**
   * Converts the nonce to a JSON representation.
   * @returns The JSON representation of the Crypto object.
   */
  toJSON() {
    return { b64Bytes: this.b64Bytes };
  }
  toU8Array(): Uint8Array {
    return base64Decode(this.b64Bytes);
  }
  /**
   * Creates a Nonce32 instance from a JSON string.
   * @param json - The JSON string representing the nonce.
   * @returns A Nonce32 instance.
   */
  static fromJSON(json: string): Nonce32 {
    return Nonce32.fromBase64(json);
  }
  /**
   * Generates a Nonce32 using the crypto.getRandomValues method.
   * @returns A Nonce32 object.
   */
  static generate(): Nonce32 {
    return new Nonce32(crypto.getRandomValues(new Uint8Array(32)));
  }
  static fromBytes(bytes: Uint8Array): Nonce32 {
    return new Nonce32(bytes);
  }
}
function createNonce32(): Nonce32 {
  return new Nonce32(crypto.getRandomValues(new Uint8Array(32)));
}
export async function registerNonce(): Promise<NoncePair> {
  const clientNonce = createNonce32();
  const endpoint = `${PATH.AUTH.Root}${PATH.AUTH.RegisterNonce}`;
  const response = await ky
    .get(endpoint, {
      searchParams: { [QueryParam.Crypto.ClientNonce]: clientNonce.b64Bytes },
    })
    .json();
  const noncePair = parseAsNoncePair(response);
  if (noncePair.client !== clientNonce) {
    throw new Error(
      `Nonce mismatch: ${noncePair.client.b64Bytes} !== ${clientNonce.b64Bytes}`
    );
  }
  return noncePair;
}
function parseAsNoncePair(data: any): NoncePair {
  return {
    client: data.client.b64Bytes,
    server: data.server.b64Bytes,
    expiry: new Date(data.expiry),
  };
}
export function makeJsonString(obj: object): string {
  return JSON.stringify(obj);
}
export function noncePairToKv(noncePair: NoncePair): NoncePair {
  const latestKey = StorageConst.Keys.NoncePairLatest;
  const noncePairKey = sessionStorage.getItem(latestKey);
  
  const newPairKey = `${QueryParam.Crypto.ClientNonce}-${noncePair.client}`;

  if (noncePairKey === null) {
    sessionStorage.setItem(newPairKey, makeJsonString(noncePair));
    sessionStorage.setItem(latestKey, newPairKey);
    return noncePair;
  }

  removeExpiredNoncePairs();

  const existingNoncePairJSON = sessionStorage.getItem(noncePairKey);
  const existingNoncePair = existingNoncePairJSON
    ? (JSON.parse(existingNoncePairJSON) as NoncePair)
    : null;

  if (existingNoncePair === null) {
    sessionStorage.setItem(newPairKey, makeJsonString(noncePair));
    sessionStorage.setItem(latestKey, newPairKey);
  }

  return existingNoncePair ?? noncePair;
}
export function removeExpiredNoncePairs(): void {
  // if sessionStorage contains any NoncePairs, delete all with expiry in the past
  if (sessionStorage.length === 0) {
    return;
  }
  const now = new Date();
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key === null) {
      continue;
    }
    if (key.startsWith(QueryParam.Crypto.ClientNonce)) {
      const item = sessionStorage.getItem(key);
      if (item === null) {
        continue;
      }
      const noncePair = parseAsNoncePair(JSON.parse(item));
      if (noncePair.expiry < now) {
        sessionStorage.removeItem(key);
      }
    }
  }
}
export function getSpecificNoncePair(keyNonce: Nonce32): NoncePair | null {
  const clientNonceBase64 = keyNonce.b64Bytes;
  const item = sessionStorage.getItem(
    `${QueryParam.Crypto.ClientNonce}-${clientNonceBase64}`
  );
  if (item === null) {
    return null;
  }
  return parseAsNoncePair(JSON.parse(item));
}
export function isNoncePairValid(noncePair: NoncePair): boolean {
  return noncePair.expiry > new Date();
}
export function loadLatestNoncePair(): NoncePair | null {
  const latestKey = StorageConst.Keys.NoncePairLatest;
  const latestNoncePair = sessionStorage.getItem(latestKey);
  if (latestNoncePair === null) {
    return null;
  }
  const latestItem = sessionStorage.getItem(latestNoncePair);
  if (latestItem === null) {
    return null;
  }
  return parseAsNoncePair(JSON.parse(latestItem));
}
export function signNonce(nonce: Nonce32, password: string): SignedNonce32 {
  const sig = kmac256(nonce.b64Bytes, password);
  const sigText = base64Encode(sig);
  return {
    nonce: nonce,
    signature: sigText,
  };
}
